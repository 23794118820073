import React from 'react';
import { Link } from 'react-router-dom';
import './Home.css';
import HomeHeader from './HomeHeader.jsx';
import HomeWhy from './HomeWhy.jsx';
import HomeProducts from './HomeProducts.jsx';
import NavBar from './NavBar'; // Import the NavBar component

function Home() {
  return (
    <div>
      {/* Add the NavBar component */}
      <NavBar />
      
      <div className="header">
        <HomeHeader />
      </div>

      <div className="body">
        <HomeWhy />
        <HomeProducts />
      </div>

      <footer className="footer">
        <div className="footer-content">
          <span className="copyright">
            &copy; {new Date().getFullYear()} New Jack Food & Paper (2018) Ltd.
          </span>
        </div>
      </footer>

    </div>
  );
}

export default Home;
