import './HomeHeader.css';

function HomeHeader() {
    return (
        <div className="banner-container">
            <div className="banner-background">
                <h1 className="banner-text">Welcome to Home</h1>
                <p className="sub-description">Explore our services and discover what we have to offer.</p>
            </div> {/* New div for solid background */}
        </div>
    )
}
export default HomeHeader;